import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import NewCustomer from "../containers/NewCustomer";

const CustomerIntakePage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="ERT Credit the fastest way to maximize your Employee Retention Credit (ERC)" />
      <NewCustomer/>
    </Layout>
  );
};

export default CustomerIntakePage;
